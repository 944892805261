export const organization = {
  list: '机构列表',
  from: '机构操作',
  view: '机构详情',
  info: '机构信息',

  logo: '机构Logo',
  keyword: '关键字查询',
  chinese_title: '机构中文名称',
  english_title: '机构英文名称',
  shipowner_id: '关联船东',
  valid_time: '机构有效期',
  register_limit: '员工注册上限',
  create_time: '注册时间',
  status: '机构状态',

  rules: {
    logo: {
      require: '机构Logo不能为空',
    },
    chinese_title: {
      require: '机构中文名称不能为空',
    },
    english_title: {
      require: '机构英文名称不能为空',
    },
    shipowner_id: {
      require: '关联船东不能为空',
    },
    register_limit: {
      require: '员工注册数不能为空',
    },
    valid_time: {
      require: '机构有效期不能为空',
    },
  },

  archive: {
    chinese_description: '机构中文简介',
    english_description: '机构英文简介',
    web_background_url: '电脑端背景图片',
    wechat_background_url: '微信端背景图片',
  },

  audit: {
    info: '机构审核',

    title: '机构审核',
    content: '审核意见',

    rules: {
      audit_status: {
        require: '审核状态不能为空',
      }
    }
  },

  member: {
    from: '机构会员操作',
    info: '机构会员',

    publisher: '发布者',

    rules: {
      mobile: {
        require: '机构会员手机号码不能为空',
      },
    }
  },

  log: {
    info: '操作日志',

    user_info: '用户信息',

    content: '操作内容',
    create_time: '操作时间',
  }
}
