export const business = {

  // 航域
  region: {
    list: '航域列表',
    from: '航域操作',

    chinese_name: '航域中文名称',
    english_name: '航域英文名称',
    chinese_short_name: '航域中文简称',
    english_short_name: '航域英文简称',

    rules: {
      chinese_name: {
        require: '航域中文名称不能为空',
      },
      english_name: {
        require: '航域英文名称不能为空',
      },
    },
  },


  // 国家
  nation: {
    list: '国家列表',
    from: '国家操作',

    chinese_name: '国家中文名称',
    english_name: '国家英文名称',
    chinese_short_name: '国家中文简称',
    english_short_name: '国家英文简称',
    chinese_alias: '国家中文别称',
    english_alias: '国家英文别称',
    english_second_alias: '英文第二别称',
    alpah_2_code: 'Alpah-2 Code',
    alpah_3_code: 'Alpah-3 Code',
    numeric: 'Numeric',

    rules: {
      chinese_name: {
        require: '国家中文名称不能为空',
      },
      english_name: {
        require: '国家英文名称不能为空',
      },
    },

    flag: {
      picture: '国旗',

      rules: {
        picture: {
          require: '国旗不能为空',
        },
      },
    }
  },

  // 港口
  harbor: {
    list: '港口列表',
    from: '港口操作',

    code: '港口代码',
    chinese_name: '港口中文名称',
    english_name: '港口英文名称',
    chinese_short_name: '港口中文简称',
    english_short_name: '港口英文简称',
    chinese_alias: '港口中文别称',
    english_alias: '港口英文别称',
    alpah_2_code: '所属国家',
    shiproute_chinese_name: '航线中文名称',
    shiproute_english_name: '航线英文名称',

    rules: {
      code: {
        require: '港口代码不能为空',
      },
      alpah_2_code: {
        require: '所属国家不能为空',
      },
      chinese_name: {
        require: '港口中文名称不能为空',
      },
      english_name: {
        require: '港口英文名称不能为空',
      },
    },
  },

  // 船东
  shipowner: {
    list: '船东列表',
    from: '船东操作',

    logo: '船东Logo',
    scac: '船东SCAC',
    chinese_name: '船东中文名称',
    english_name: '船东英文名称',
    chinese_short_name: '船东中文简称',
    english_short_name: '船东英文简称',
    address: '船东详细地址',
    mobile: '船东联系电话',
    email: '船东联系邮箱',
    share_url: '分享图片',
    visit_status: '访问状态',

    rules: {
      logo: {
        require: '船东Logo不能为空',
      },
      chinese_name: {
        require: '船东中文名称不能为空',
      },
      english_name: {
        require: '船东英文名称不能为空',
      },
    },

    detail: {
      chinese_description: '中文简介信息',
      english_description: '英文简介信息',
    }
  },

  // 航线负责人
  manager: {
    list: '航线负责人列表',
    from: '航线负责人操作',

    shipowner_id: '所属船东',
    chinese_name: '负责人中文名称',
    english_name: '负责人英文名称',
    chinese_short_name: '负责人中文简称',
    english_short_name: '负责人英文简称',
    mobile: '负责人联系电话',
    mobile2: '负责人备用电话',
    email: '负责人联系邮箱',

    rules: {
      shipowner_id: {
        require: '所属船东不能为空',
      },
      chinese_name: {
        require: '负责人中文名称不能为空',
      },
      english_name: {
        require: '负责人英文名称不能为空',
      },
    },
  },

  // 航线
  shiproute: {
    list: '航线列表',
    from: '航线操作',

    shipowner_id: '所属船东',
    manager_id: '航线负责人',
    code: '航线代码',
    chinese_name: '航线中文名称',
    english_name: '航线英文名称',
    description: '航线简介信息',

    rules: {
      shipowner_id: {
        require: '所属船东不能为空',
      },
      manager_id: {
        require: '航线负责人不能为空',
      },
      code: {
        require: '航线代码不能为空',
      },
      chinese_name: {
        require: '航线中文名称不能为空',
      },
      english_name: {
        require: '航线英文名称不能为空',
      },
    },

    detail: {
      info: '航线数据',

      region_id: '航线区域',
      placeholder_input_option: '请先输入选项再添加',
    }
  },

  // 普货信息
  information: {
    list: '最新普货列表',
    from: '最新普货操作',

    harbor_info: '港口信息',
    start_harbor_info: '起始港信息',
    end_harbor_info: '目的港信息',

    shipowner_id: '所属船东',
    shiproute_id: '所属航线',
    manager_id: '航线负责人',
    category_id: '运输分类',
    machine_id: '运输工具',
    title: '船名',
    publish_time: '发布时间',
    create_time: '上传时间',

    harbor_code: '途经港口',
    second_harbor_code: '第二途经港口',
    third_harbor_code: '第三途经港口',
    start_time: '开始时间',
    end_time: '结束时间',
    is_load: '是否装港',
    is_unload: '是否卸港',
    price: '运价值',
    placeholder_input_option: '请先输入选项再添加',

    source: '来源',
    disclaimer: '免责条款',
    remark: '备注',

    rules: {
      category_id: {
        require: '运输分类不能为空',
      },
      shipowner_id: {
        require: '所属船东不能为空',
      },
      shiproute_id: {
        require: '所属航线不能为空',
      },
      manager_id: {
        require: '航线负责人不能为空',
      },
      title: {
        require: '船名不能为空',
      },
      publish_time: {
        require: '发布时间不能为空',
      },
    },

    category: {
      list: '运输分类列表',
      from: '运输分类操作',
      view: '运输分类详情',
      info: '运输分类',

      chinese_title: '中文名称',
      english_title: '英文名称',

      rules: {
        chinese_title: {
          require: '中文名称不能为空',
          length: '中文名称最大长度为20',
        },
        english_title: {
          require: '英文名称不能为空',
          length: '英文名称最大长度为50',
        },
      }
    },

    machine: {
      list: '运输工具列表',
      from: '运输工具操作',
      view: '运输工具详情',
      info: '工具',

      chinese_title: '中文名称',
      english_title: '英文名称',

      rules: {
        chinese_title: {
          require: '中文名称不能为空',
          length: '中文名称最大长度为20',
        },
        english_title: {
          require: '英文名称不能为空',
          length: '英文名称最大长度为50',
        },
      }
    },

    import: {
      list: '导入列表',
      import: '批量导入',
      download: '模板下载',
      info: '导入',

      title: '导入文件名称',
      remark: '备注',
      success_total: '导入成功数量',
      error_total: '导入失败数量',
      progress: '导入进度',
      generator: '上传人',
      create_time: '导入时间',

      log: {
        list: '日志列表',
        info: '日志',

        sheet_name: '工作表名称',
        sheet_line: '工作表行号',
        content: '异常内容',
        create_time: '产生时间',
      },
    },
  },

  // 滚装信息
  roll: {
    list: '最新滚装列表',
    from: '最新滚装操作',

    harbor_info: '港口信息',
    track_harbor_info: '途经港口信息',
    transfer_harbor_info: '中转港口信息',
    transfer_harbor: '中转港口',

    shipowner_id: '所属船东',
    shiproute_id: '所属航线',
    manager_id: '航线负责人',
    category_id: '运输分类',
    machine_id: '运输工具',
    voyage_number: '航次号',
    title: '船名',
    publish_time: '发布时间',

    harbor_code: '途经港口',
    start_time: '开始时间',
    end_time: '结束时间',
    is_load: '是否装港',
    is_unload: '是否卸港',
    is_transfer: '是否中转',
    price: '运价值',
    placeholder_input_option: '请先输入选项再添加',

    disclaimer: '免责条款',
    remark: '备注',

    rules: {
      category_id: {
        require: '运输分类不能为空',
      },
      shipowner_id: {
        require: '所属船东不能为空',
      },
      shiproute_id: {
        require: '所属航线不能为空',
      },
      manager_id: {
        require: '航线负责人不能为空',
      },
      voyage_number: {
        require: '船次号不能为空',
      },
      title: {
        require: '船名不能为空',
      },
      publish_time: {
        require: '发布时间不能为空',
      },
    },

    import: {
      list: '导入列表',
      import: '批量导入',
      download: '模板下载',
      info: '导入',

      title: '导入文件名称',
      remark: '备注',
      success_total: '导入成功数量',
      error_total: '导入失败数量',
      progress: '导入进度',
      generator: '上传人',
      create_time: '导入时间',

      log: {
        list: '日志列表',
        info: '日志',

        sheet_name: '工作表名称',
        sheet_line: '工作表行号',
        content: '异常内容',
        create_time: '产生时间',
      },
    },
  },

  // 班轮信息
  regular: {
    list: '最新班轮列表',
    from: '最新班轮操作',

    harbor_info: '港口信息',
    track_harbor_info: '途经港口信息',
    transfer_harbor_info: '中转港口信息',
    transfer_harbor: '中转港口',

    shipowner_id: '所属船东',
    shiproute_id: '所属航线',
    manager_id: '航线负责人',
    category_id: '运输分类',
    machine_id: '运输工具',
    voyage_number: '航次号',
    title: '船名',
    publish_time: '发布时间',

    harbor_code: '途经港口',
    start_time: '开始时间',
    end_time: '结束时间',
    is_load: '是否装港',
    is_unload: '是否卸港',
    is_transfer: '是否中转',
    price: '运价值',
    placeholder_input_option: '请先输入选项再添加',

    disclaimer: '免责条款',
    remark: '备注',

    rules: {
      category_id: {
        require: '运输分类不能为空',
      },
      shipowner_id: {
        require: '所属船东不能为空',
      },
      shiproute_id: {
        require: '所属航线不能为空',
      },
      manager_id: {
        require: '航线负责人不能为空',
      },
      voyage_number: {
        require: '船次号不能为空',
      },
      title: {
        require: '船名不能为空',
      },
      publish_time: {
        require: '发布时间不能为空',
      },
    },

    import: {
      list: '导入列表',
      import: '批量导入',
      download: '模板下载',
      info: '导入',

      title: '导入文件名称',
      remark: '备注',
      success_total: '导入成功数量',
      error_total: '导入失败数量',
      progress: '导入进度',
      generator: '上传人',
      create_time: '导入时间',

      log: {
        list: '日志列表',
        info: '日志',

        sheet_name: '工作表名称',
        sheet_line: '工作表行号',
        content: '异常内容',
        create_time: '产生时间',
      },
    },

    recommend: {
      from: '首页推荐',
      info: '首页推荐',

      start_harbor_code: '高亮起始港',
      end_harbor_code: '高亮目的港',
      is_recommend: '推荐状态',

      rules: {
        is_recommend: {
          require: '推荐状态不能为空',
        },
      }
    }
  },


  // 重吊信息
  heavy: {
    list: '最新重吊列表',
    from: '最新重吊操作',

    harbor_info: '港口信息',
    start_harbor_info: '起始港信息',
    end_harbor_info: '目的港信息',

    shipowner_id: '所属船东',
    shiproute_id: '所属航线',
    manager_id: '航线负责人',
    category_id: '运输分类',
    machine_id: '运输工具',
    title: '船名',
    voyage_number: '航次号',
    publish_time: '发布时间',
    create_time: '上传时间',

    harbor_code: '途经港口',
    second_harbor_code: '第二途经港口',
    third_harbor_code: '第三途经港口',
    start_time: '开始时间',
    end_time: '结束时间',
    is_load: '是否装港',
    is_unload: '是否卸港',
    price: '运价值',
    placeholder_input_option: '请先输入选项再添加',

    source: '来源',
    disclaimer: '免责条款',
    remark: '备注',

    rules: {
      category_id: {
        require: '运输分类不能为空',
      },
      shipowner_id: {
        require: '所属船东不能为空',
      },
      shiproute_id: {
        require: '所属航线不能为空',
      },
      manager_id: {
        require: '航线负责人不能为空',
      },
      title: {
        require: '船名不能为空',
      },
      publish_time: {
        require: '发布时间不能为空',
      },
    },

    import: {
      list: '导入列表',
      import: '批量导入',
      download: '模板下载',
      info: '导入',

      title: '导入文件名称',
      remark: '备注',
      success_total: '导入成功数量',
      error_total: '导入失败数量',
      progress: '导入进度',
      generator: '上传人',
      create_time: '导入时间',

      log: {
        list: '日志列表',
        info: '日志',

        sheet_name: '工作表名称',
        sheet_line: '工作表行号',
        content: '异常内容',
        create_time: '产生时间',
      },
    },
  },

  // 集装箱信息
  container: {
    // 集装箱船期
    schedule: {
      list: '集装箱列表',
      from: '集装箱操作',
      view: '集装箱详情',

      harbor_info: '港口信息',
      shipowner_info: '船公司信息',
      schedule_info: '船期信息',
      transit_info: '中转信息',

      isTransit: '是否中转',
      routeCode: '航线代码',
      shipowner_id: '船东信息',
      scac: '船公司SCAC',
      polName: '起运港名称',
      polCode: '起运港五字码',
      polCountry: '起运港国家',
      podName: '目的港',
      podCode: '目的港五字码',
      podCountry: '目的港国家',
      routeEtd: '计划离港班期',
      staticEtd: '计划离港日期',
      etd: '预计离港日期',
      routeEta: '计划到港班期',
      staticEta: '计划到港日期',
      totalDuration: '预计航程',
      cyCutoff: '截关时间',
      gp20: '20GP报价',
      gp40: '40GP报价',
      hq40: '40HQ报价',

      harbor_code: '途经港口',
      start_time: '开始时间',
      end_time: '结束时间',
      is_load: '是否装港',
      is_unload: '是否卸港',
      price: '运价值',
      placeholder_input_option: '请先输入选项再添加',

      disclaimer: '免责条款',
      remark: '备注',

      // 未使用
      shareCabins: '共舱结果集',
      polTerminalCn: '起运港标准码头',
      pod: '目的港原始英文名',
      podTerminalCn: '目的港标准码头',


      rules: {
        category_id: {
          require: '运输分类不能为空',
        },
        shipowner_id: {
          require: '所属船东不能为空',
        },
        shiproute_id: {
          require: '所属航线不能为空',
        },
        manager_id: {
          require: '航线负责人不能为空',
        },
        title: {
          require: '船名不能为空',
        },
        publish_time: {
          require: '发布时间不能为空',
        },
      },

      log: {
        start_code: '起始港',
        end_code: '目的港',
        search_date: '查询时间',
      }
    },

    import: {
      list: '导入列表',
      import: '批量导入',
      download: '模板下载',
      info: '导入',

      title: '导入文件名称',
      remark: '备注',
      success_total: '导入成功数量',
      error_total: '导入失败数量',
      progress: '导入进度',
      create_time: '导入时间',

      log: {
        list: '日志列表',
        info: '日志',

        sheet_name: '工作表名称',
        sheet_line: '工作表行号',
        content: '异常内容',
        create_time: '产生时间',
      },
    },
  },

  // 报价
  quote: {
    // 普通货物报价
    normal: {
      list: '普通货物报价列表',
      from: '普通货物报价操作',

      machine_id: '船只类型',
      harbor_name: '港口名称',
      harbor_code: '港口代码',
      shiproute_code: '航线代码',
      publish_time: '报价时间',
      short_title: '货物类型',
      description: '条款',
      content: '费率',

      rules: {
        machine_id: {
          require: '船只类型不能为空',
        },
        harbor_code: {
          require: '港口名称不能为空',
        },
        publish_time: {
          require: '报价时间不能为空',
        }
      },

      detail: {
        cargo_type: '货物类型',
        clause: '条款',
        price: '费率',
      },

      import: {
        list: '导入列表',
        import: '批量导入',
        download: '模板下载',
        info: '导入',

        title: '导入文件名称',
        remark: '备注',
        success_total: '导入成功数量',
        error_total: '导入失败数量',
        progress: '导入进度',
        create_time: '导入时间',

        log: {
          list: '日志列表',
          info: '日志',

          sheet_name: '工作表名称',
          sheet_line: '工作表行号',
          content: '异常内容',
          create_time: '产生时间',
        },
      },
    },

    // 集装箱报价
    container: {
      list: '集装箱货物报价列表',
      from: '集装箱货物报价操作',

      shipowner_id: '船东',
      start_harbor_name: '起始港口名称',
      end_harbor_name: '目的港口名称',
      start_harbor_code: '起始港口代码',
      end_harbor_code: '目的港口代码',
      publish_time: '报价时间',
      remark: '备注',

      rules: {
        shipowner_id: {
          require: '船东不能为空',
        },
        start_harbor_code: {
          require: '港口名称不能为空',
        },
        end_harbor_code: {
          require: '港口名称不能为空',
        },
        publish_time: {
          require: '报价时间不能为空',
        }
      },

      detail: {
        title: '箱型',
        price: '价格',
      },

      import: {
        list: '导入列表',
        import: '批量导入',
        download: '模板下载',
        info: '导入',

        title: '导入文件名称',
        remark: '备注',
        success_total: '导入成功数量',
        error_total: '导入失败数量',
        progress: '导入进度',
        create_time: '导入时间',

        log: {
          list: '日志列表',
          info: '日志',

          sheet_name: '工作表名称',
          sheet_line: '工作表行号',
          content: '异常内容',
          create_time: '产生时间',
        },
      },
    }
  },


  client: {
    list: '客户信息列表',
    from: '客户信息操作',

    logo: '客户Logo',
    title: '客户名称',
    language: '客户语言',

    rules: {
      logo: {
        require: '客户Logo不能为空',
      },
      title: {
        require: '客户名称不能为空',
      },
    },
  },


  article: {
    list: '资讯信息列表',
    from: '资讯信息操作',

    picture: '资讯封面',
    title: '资讯标题',
    short_title: '资讯副标题',
    description: '资讯描述',
    content: '资讯内容',
    language: '资讯语言',
    article_status: '资讯状态',

    rules: {
      picture: {
        require: '资讯封面不能为空',
      },
      title: {
        require: '资讯标题不能为空',
      },
      short_title: {
        require: '资讯副标题不能为空',
      },
      description: {
        require: '资讯描述不能为空',
      },
    },
  },

  supplier: {
    list: '供应商列表',
    from: '供应商操作',

    company_logo: '供应商Logo',
    company_name: '供应商名称',
    contact_name: '联系人姓名',
    contact_mobile: '联系人电话',
    description: '供应商描述',
    address: '供应商地址',
    content: '供应商详情',

    rules: {
      company_logo: {
        require: '供应商Logo不能为空',
      },
      company_name: {
        require: '供应商名称不能为空',
      },
      contact_name: {
        require: '联系人姓名不能为空',
      },
      contact_mobile: {
        require: '联系人电话不能为空',
      },
      description: {
        require: '供应商描述不能为空',
      },
    },
  },

  activation: {
    code: {
      create: '生成',
      from: '生成兑换码',

      prefix: '兑换码前缀',
      total: '生成数量',

      code: '兑换码',
      face_value: '兑换面值',
      valid_time: '有效期',
      generator: '创建者',
      is_grant: '发放状态',
      is_use: '兑换状态',

      rules: {
        prefix: {
          require: '兑换码前缀不能为空',
        },
        valid_time: {
          require: '有效期不能为空',
        },
        total: {
          require: '生成数量不能为空',
        },
      },


      export: {
        from: '导出兑换码',

        total: '导出数量',

        rules: {
          total: {
            require: '导出数量不能为空',
          },
        },
      },
    },

    log: {
      code: '兑换码',
      create_time: '兑换时间',
    },
  },

  phone: {
    code: {
      list: '手机区号列表',
      from: '手机区号操作',

      title: '国家名称',
      code: '手机区号',

      rules: {
        title: {
          require: '国家名称不能为空',
        },
        code: {
          require: '手机区号不能为空',
        },
      },
    },
  },

  wechat: {
    page: {
      list: '小程序页面列表',
      from: '小程序页面操作',

      title: '页面名称',
      url: '页面地址',

      rules: {
        title: {
          require: '页面名称不能为空',
        },
        url: {
          require: '页面地址不能为空',
        },
      },
    },

    tools: {
      list: '小程序工具列表',
      from: '小程序工具操作',

      title: '工具名称',
      language: '客户语言',
      picture_enable: '启用图片',
      picture_disable: '禁用图片',
      url: '路由地址',

      rules: {
        title: {
          require: '工具名称不能为空',
        },
        url: {
          require: '路由地址不能为空',
        },
      },
    },
  },

  archived: {
    information: {
      from: '历史普货操作'
    },
    roll: {
      from: '历史滚装操作'
    },
    regular: {
      from: '历史班轮操作'
    },
    heavy: {
      from: '历史重吊操作'
    }
  },

  publish: {
    template: {
      form: '船期模板操作',
      info: '船期模板',

      title: '模板标题',

      rules: {
        title: {
          require: '模板标题不能为空',
        },
      },

      schedule: {
        form: '船期数据操作',
        info: '船期数据',

        add_group: '新增分组',
        remove_group: '删除分组',

        add_schedule: '新增船期',
        remove_schedule: '删除船期',

        add_route: '新增港口',
        remove_route: '删除港口',

        publish_time: '发布时间',
        group_title: '航运分组',
        machine_id: '运输工具',
        ship_name: '船名',
        voyage_number: '航次号',
        harbor_code: '港口名称',
        second_harbor_code: '第二港口名称',
        third_harbor_code: '第三港口名称',
        use_common: '共用',
        transit_time: '起止时间',
        start_time: '开始时间',
        end_time: '结束时间',
        to: '至',
        harbor_type: '港口类型',
        is_price: '运价值显示',
        show: '显示',
        hidden: '隐藏',
        manager_id: '航线负责人',
        shipowner_id: '所属船东',
        shiproute_id: '所属航线',
        category_id: '运输分类',
        create_time: '上传时间',

        is_load: '是否装港',
        is_unload: '是否卸港',

        success: '发布完成',
        placeholder_input_option: '请先输入选项再添加',

        source: '来源',
        disclaimer: '免责条款',
        remark: '备注',
        vehicle: '车辆',
        equipment: '设备',
        steel: '钢材',
        ton_bag: '吨袋',

        rules: {
          publish_time: {
            require: '发布时间不能为空',
          },
        },
      }
    }
  }

}
